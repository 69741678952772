
import React from 'react';
import DetailedInfo from "./DetailedInfo"


const Info = ({ movie }) => {
  return (
    <div>
      {/* <h1>Info Page</h1> */}
      <DetailedInfo movie={movie} />
    </div>
  );
};

export default Info;
