
// import "./app.scss"
// import Login from "./pages/login/Login";
// import Register from "./pages/register/Register";
// import Home from "./pages/home/Home";
// import Info from "./pages/Info/Info";

// import {
//   BrowserRouter as Router,
//   Routes,
//   Route
// } from "react-router-dom";
// import { Navigate } from 'react-router-dom';
// import Watch from "./pages/watch/Watch";
// import { useContext } from "react";
// import { AuthContext } from "./authContext/AuthContext";
// import Welcome from "./Welcome";
// import FAQs from "./components/FAQs";  
// import DetailedInfo from './pages/Info/DetailedInfo';





// const App = () => {
//   const {user} = useContext(AuthContext)
 
//   return (
//     <Router>
//       <Routes>
//       <Route path="/faqs" element={<FAQs />} />
//        <Route path="/" element={!user ? <Welcome/> : <Navigate to = "/"/>} />
//        <Route path="/register" element={!user ? <Register/> : <Navigate to = "/"/>} />
//        <Route path="/login" element= {!user ? <Login/> : <Navigate to = "/"/>} /> 
//        { user &&
//        (
//         <>
       
//             <Route path="/movies" element={<Home type="movie" />} /> 
            
//             <Route path="/series" element={ <Home type="series" />} /> 

//             <Route path="/my list" element={ <Home type="my list" />} /> 
//             <Route path="/info" element={<Info  element={<DetailedInfo />} />} />
          
//             <Route path="/watch" element={<Watch />} /> 
           
//        </>
//        )
//        }
       
      
        
            
        
//       </Routes>
//     </Router>
    
    
//   )
// };

// export default App;

import "./app.scss";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register"; // Keep this commented if not used
import Home from "./pages/home/Home";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Watch from "./pages/watch/Watch";
import Info from "./pages/Info/Info";
import { useContext } from "react";
import { AuthContext } from "./authContext/AuthContext";
import Welcome from "./Welcome";
import FAQs from "./components/FAQs";
import DetailedInfo from './pages/Info/Info';

const App = () => {
  const { user } = useContext(AuthContext);

  return (
    <Router>
    <Routes>
     <Route exact path="/" element={user ? <Home/> : <Navigate to = "/register"/>} /> 
     <Route path="/register" element={!user ? <Register/> : <Navigate to = "/"/>} /> 
     <Route path="/login" element= {!user ? <Login/> : <Navigate to = "/"/>} /> 
     <Route path="/info" element={<Info />} /> 
     { user &&
     (
      <>
     
          <Route path="/movies" element={<Home type="movie" />} /> 
          
          <Route path="/series" element={ <Home type="series" />} /> 
        
          <Route path="/watch" element={<Watch />} /> 
         
     </>
     )
     }
     
    
      
          
      
    </Routes>
  </Router>
  );
};

export default App;
