import React, { useState, useEffect, useRef } from 'react';
import "./listItem.scss";
import { Add, PlayArrow, ThumbDownAltOutlined, ThumbUpAltOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import axios from 'axios';

export default function ListItem({ index, item }) {
  const [isHovered, setIsHovered] = useState(false);
  const [movie, setMovie] = useState({});
  const cacheRef = useRef({}); // Cache reference to store movie data
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  });

  useEffect(() => {
    const getMovie = async () => {
      if (cacheRef.current[item]) {
        // If movie data is already cached, use it
        setMovie(cacheRef.current[item]);
      } else {
        try {
          const res = await axiosInstance.get("/movies/find/" + item);
          setMovie(res.data);
          cacheRef.current[item] = res.data; // Cache the fetched movie data
        } catch (err) {
          console.log(err);
        }
      }
    };

    getMovie();
  }, [item, axiosInstance]);

  const fetchMovieVideo = async () => {
    try {
      const res = await axiosInstance.get("/movies/find/" + item + "/video", {
        headers: {
          token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      });
      return res.data.video;
    } catch (err) {
      console.log(err);
    }
  };

  const handleLinkClick = async (e) => {
    e.preventDefault();
    const videoUrl = await fetchMovieVideo();
    window.location.href = `/watch?video=${encodeURIComponent(videoUrl)}`;
  };

  return (
    <div
      className="listItem"
      style={{ left: isHovered && index * 225 - 50 + index * 2.5 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={movie?.imgSm} alt="" />
      {isHovered && (
        <>
          <video src={movie.trailer} autoPlay={true} loop />
          <div className="itemInfo">
            <div className="icons">
              <PlayArrow className='icon' />
              <Add className='icon' />
              <ThumbUpAltOutlined className='icon' />
              <ThumbDownAltOutlined className='icon' />
            </div>
            <div className="itemInfoTop">
              <span>{movie.duration}</span>
              <span className='limit'>+{movie.limit}</span>
              <span>{movie.year}</span>
            </div>
            <div className="desc">
              {movie.desc}
            </div>
            <div className="genre">{movie.genre}</div>
          </div>
        </>
      )}
      <Link to="#" onClick={handleLinkClick} state={{ movie }}>
        <div className="overlay"></div>
      </Link>
    </div>
  );
}
