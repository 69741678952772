import classes from "./InputCard.module.css";

const InputCard = (props) => {
  const clickHandler = (e) => {
    props.clickHandler(e);
  };
  return (
      <div
          onClick={clickHandler}
          className={`${classes.inputContainer} ${props.className}`}
      >
        {props.children}
      </div>
  );
};

export default InputCard;
