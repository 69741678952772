import { createRoot } from "react-dom/client";
import React from "react";
import App from "./App";
import { AuthContextProvider } from "./authContext/AuthContext";

// Ensure the root element is properly selected
const root = createRoot(document.getElementById("root"));

root.render(
  <AuthContextProvider>
    <App />
  </AuthContextProvider>
);
