import classes from "./Background.module.css"; // I assumed the CSS module's name based on convention. Adjust if necessary.

const Background = (props) => {
  return (
      <div className={classes.backgroundContainer}>
      </div>
  );
};

export default Background;
