import React, { useState, useEffect } from 'react';
import "./featured.scss";
import { InfoOutlined, PlayArrow, Close } from '@material-ui/icons'; // Import Close icon
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

export default function Featured({ type, setGenre }) {
  const [content, setContent] = useState({});
  const [videoUrl, setVideoUrl] = useState('');
  const [isVideoVisible, setIsVideoVisible] = useState(false); // State to control video visibility
  const navigate = useNavigate();

  // Create the axios instance
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 5000
  });

  const userString = localStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : null;
  const token = user ? `Bearer ${user.accessToken}` : undefined;

  const fetchRandomMovie = async (type) => {
    try {
      const response = await axiosInstance.get(`/movies/random?type=${type}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch random movie:', error);
      return null; // Return null on error
    }
  };

  const truncate = (str, maxLen) => {
    return str?.length > maxLen ? str.substr(0, maxLen - 1) + "..." : str;
  };

  useEffect(() => {
    const loadMovie = async () => {
      const movieData = await fetchRandomMovie(type);
      if (movieData) {
        setContent(movieData);
      }
    };
    loadMovie();
  }, [type]); 

  const fetchVideoUrl = async () => {
    if (!token) {
      navigate('/login');
      return;
    }

    if (!content._id) {
      console.error("Content ID is undefined, cannot fetch video URL");
      return;
    }
    try {
      const res = await axiosInstance.get(`/movies/find/${content._id}/video`);
      console.log("Fetched video URL:", res.data.video);
      setVideoUrl(res.data.video);
      setIsVideoVisible(true); // Show the video when Play is clicked
    } catch (err) {
      console.error("Error fetching video URL:", err);
    }
  };

  const closeVideo = () => {
    setIsVideoVisible(false); // Close the video modal
  };

  return (
    <div className="featured">
      {type && (
        <div className="category">
          <span>{type === "movie" ? "Movies" : "Series"}</span>
          <select name="genre" id="genre" onChange={e => setGenre(e.target.value)}>
            <option>Genre</option>
            {/* Updated with all genres */}
            <option value="Love">Love</option>
            <option value="Three Angels">Three Angels</option>
            <option value="Temperance">Temperance</option>
            <option value="Breach Repairers">Breach Repairers</option>
            <option value="Changed Life">Changed Life</option>
            <option value="Health">Health</option>
            <option value="Prophecy">Prophecy</option>
            <option value="Sanctuary">Sanctuary</option>
            <option value="Hymns">Hymns</option>
            <option value="Songs">Songs</option>
            <option value="Sabbath">Sabbath</option>
            <option value="Faith">Faith</option>
            <option value="Hope">Hope</option>
            <option value="Inspirational">Inspirational</option>
            <option value="Documentary">Documentary</option>
            <option value="Salvation">Salvation</option>
            <option value="Commandment">Commandment</option>
            <option value="Family">Family</option>
            <option value="Praise">Praise</option>
            <option value="Education">Education</option>
            <option value="Lecture">Lecture</option>
            <option value="Life">Life</option>
            <option value="Babylon">Babylon</option>
            <option value="Revival and Reformation">Revival and Reformation</option>
            <option value="Faith and Miracles">Faith and Miracles</option>
            <option value="End Times">End Times</option>
            <option value="Stewardship">Stewardship</option>
            <option value="Creation and Nature">Creation and Nature</option>
            <option value="Missions and Evangelism">Missions and Evangelism</option>
            <option value="Character Development">Character Development</option>
            <option value="Persecution and Martyrdom">Persecution and Martyrdom</option>
            <option value="Marriage and Relationships">Marriage and Relationships</option>
            <option value="Spiritual Warfare">Spiritual Warfare</option>
            <option value="Christian Service">Christian Service</option>
            <option value="Judgment">Judgment</option>
            <option value="Bible Study and Doctrine">Bible Study and Doctrine</option>
            <option value="Youth and Young Adults">Youth and Young Adults</option>
            <option value="Music and Worship">Music and Worship</option>
            <option value="Martyrs and Heroes of Faith">Martyrs and Heroes of Faith</option>
          </select>
        </div>
      )}

      {content.img && <img src={content.img} alt={content.title} />}
      <div className="info">
        {content.imgTitle && <img src={content.imgTitle} alt="title" />}
        <span className="desc">
          {truncate(content.desc, 150)}
        </span>
        <div className="buttons">
          <button className='play' onClick={fetchVideoUrl}>
            <PlayArrow />
            <span>Play</span>
          </button>
          <Link to = "/info" state = { { movie: content } }>
          <button className="more">
            <InfoOutlined />
            <span>Info</span>
          </button>
        </Link>
        </div>
      </div>

      {/* Video Modal */}
      {isVideoVisible && (
        <div className="video-modal">
          <button className="close-btn" onClick={closeVideo}>
            <Close />
          </button>
          <video controls autoPlay src={videoUrl} className="video-player" />
        </div>
      )}
    </div>
  );
}
