import LanguageSelect from "../UI/LanguageSelect/LanguageSelect";
import classes from "./Footer.module.css";
import { Link } from 'react-router-dom';
import FAQs from "../FAQs";


const Footer = (props) => {
  return (
    <div className={classes.footerMaster}>
      <div className={classes.footer}>
        {/*<span>*/}
        {/*  Questions? <a href="tel:236 889 7950" className={classes.whiteLink} >Call 236 889 7950</a>*/}
        {/*</span>*/}

      {/* // <Route path="/faqs" element={<FAQs />} /> */}

        <div>
          <ul className={classes.contactList}>
          <li>
              <Link to="/faqs" className={classes.whiteLink}>FAQ</Link>
            </li>
            <li>
              <a href="#" className={classes.whiteLink}>Help Center</a>
            </li>
            <li>
              <a href="#" className={classes.whiteLink}>Account</a>
            </li>
            <li>
              <a href="#" className={classes.whiteLink}>Media Center</a>
            </li>
            <li>
              <a href="#" className={classes.whiteLink}>Investor Relations</a>
            </li>
            <li>
              <a href="#" className={classes.whiteLink}>Jobs</a>
            </li>
            <li>
              <a href="#" className={classes.whiteLink}>Redeem Gift Cards</a>
            </li>
            <li>
              <a href="#" className={classes.whiteLink}>Buy Gift Cards</a>
            </li>
            <li>
              <a href="#" className={classes.whiteLink}>Ways to Watch</a>
            </li>
            <li>
              <a href="#" className={classes.whiteLink}>Terms of Use</a>
            </li>
            <li>
              <a href="#" className={classes.whiteLink}>Privacy</a>
            </li>
            <li>
              <a href="#" className={classes.whiteLink}>Cookie Preferences</a>
            </li>
            <li>
              <a href="#" className={classes.whiteLink}>Corporate Information</a>
            </li>
            <li>
              <a href="#" className={classes.whiteLink}>Contact Us</a>
            </li>
            <li>
              <a href="#" className={classes.whiteLink}>Speed Test</a>
            </li>
            <li>
              
              <a href="#" className={classes.whiteLink}>Legal Notices</a>
            </li>
            <li>
              <a href="#" className={classes.whiteLink}>Only on Breachfix</a>
            </li>
            <li>
              <a href="https://amen.bible/" className={classes.whiteLink}>Amen.bible</a>
            </li>
          </ul>
         
        </div>
        <div className={classes.footerLangSelect}>
          <LanguageSelect id="footer_lang_select" className="" />
        </div>
        <span className={classes.copyright}>Breachfix Canada</span>
        {/*<div className={classes.attribution}>*/}
        {/*  Coded by:{" "}*/}
        {/*  <a href="https://github.com/JonathanP4/">Breachfix</a>*/}
        {/*  <br />*/}
        {/*  Original site:{" "}*/}
        {/*  <a href="https://breachfix.com/">Breachfix</a>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default Footer;
