import React, { useState, useEffect, useRef } from 'react';
import './DetailedInfo.scss';
import { useLocation } from 'react-router-dom';
import { PlayArrow, Add, ThumbDownAltOutlined, ThumbUpAltOutlined, Close, NavigateBefore, NavigateNext } from '@material-ui/icons';
import axios from 'axios';

const DetailedInfo = () => {
  const location = useLocation();
  const initialMovie = location.state?.movie; // Get the specific movie passed from "Featured"
  const [movie, setMovie] = useState(initialMovie); // State for the current movie
  const [isVideoVisible, setIsVideoVisible] = useState(false); // State to control video visibility
  const [videoUrl, setVideoUrl] = useState(''); // Set the video URL
  const [relatedMovies, setRelatedMovies] = useState([]); // State for related movies
  const [isSeries, setIsSeries] = useState(false); // State to check if it's a series
  const [currentMovieIndex, setCurrentMovieIndex] = useState(0); // Track the index of the current movie
  const trailerRef = useRef(null); // Ref to control the trailer video

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 5000
  });

  // Check if the movie is part of a series by comparing its ID with lists
  const checkIfSeries = async (movieId) => {
    try {
      const response = await axiosInstance.get('/lists');
      const lists = response.data;

      // Check if the movie ID is found in any of the lists
      for (const list of lists) {
        if (list.content.includes(movieId)) {
          setIsSeries(true);

          // Fetch the details of related movies by their IDs
          const relatedMovieDetails = await Promise.all(
            list.content
              .filter(id => id !== movieId) // Exclude the current movie's ID
              .map(async id => {
                const movieResponse = await axiosInstance.get(`/movies/find/${id}`);
                return movieResponse.data;
              })
          );

          setRelatedMovies(relatedMovieDetails); // Set the related movies
          return;
        }
      }

      setIsSeries(false); // If no match is found, it's not a series
    } catch (err) {
      console.error("Failed to check if movie is a series:", err);
    }
  };

  // Function to fetch the video URL for a specific movie
  const fetchMovieVideo = async (movieId) => {
    try {
      const res = await axiosInstance.get(`/movies/find/${movieId}/video`, {
        headers: {
          token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      });
      setVideoUrl(res.data.video);
      setIsVideoVisible(true); // Show the video when Play is clicked
      if (trailerRef.current) {
        trailerRef.current.pause(); // Stop the trailer from playing
      }
    } catch (err) {
      console.error("Failed to fetch movie video:", err);
    }
  };

  // Play the next related movie
  const playNextMovie = () => {
    if (currentMovieIndex < relatedMovies.length - 1) {
      const nextMovie = relatedMovies[currentMovieIndex + 1];
      setCurrentMovieIndex(prevIndex => prevIndex + 1); // Update the index for the next movie
      setMovie(nextMovie); // Update the current movie
      fetchMovieVideo(nextMovie._id); // Fetch and play the next movie's video
    }
  };

  // Play the previous related movie
  const playPreviousMovie = () => {
    if (currentMovieIndex > 0) {
      const prevMovie = relatedMovies[currentMovieIndex - 1];
      setCurrentMovieIndex(prevIndex => prevIndex - 1); // Update the index for the previous movie
      setMovie(prevMovie); // Update the current movie
      fetchMovieVideo(prevMovie._id); // Fetch and play the previous movie's video
    }
  };

  // Play the clicked related movie
  const playRelatedMovie = (index) => {
    const relatedMovie = relatedMovies[index];
    setCurrentMovieIndex(index); // Update the index to the clicked movie
    setMovie(relatedMovie); // Update the current movie
    fetchMovieVideo(relatedMovie._id); // Fetch and play the clicked movie's video
  };

  useEffect(() => {
    if (movie && movie._id) {
      checkIfSeries(movie._id); // Check if this movie is part of a series
      // Do not fetch the movie video automatically
      // Instead, the trailer will play automatically, and the full movie only on user interaction
    }
  }, [movie]);

  if (!movie) {
    return <div className="detailedInfo">Movie details not available.</div>;
  }

  const closeVideo = () => {
    setIsVideoVisible(false);
    if (trailerRef.current) {
      trailerRef.current.play(); // Resume the trailer if needed when the movie stops
    }
  };

  return (
    <div className="detailedInfo">
      <div className="backgroundImage" style={{ backgroundImage: `url(${movie.img})` }}>
        <div className="darkShade" />
        <div className="content">
          <div className="movieHeader">
            <img src={movie.img} alt={movie.title} className="topImage" />
            <div className="movieControls">
              <button className='play' onClick={() => fetchMovieVideo(movie._id)}>
                <PlayArrow />
                <span>Play</span>
              </button>
              <div className="additionalInfo">
                <span className="year">{movie.year}</span>
                <span className="limit">+{movie.limit}</span>
                <span className="duration">{movie.duration}</span>
                <button className="iconButton thumbsUp">
                  <ThumbUpAltOutlined />
                </button>
                <button className="iconButton thumbsDown">
                  <ThumbDownAltOutlined />
                </button>
                <button className="iconButton add">
                  <Add />
                </button>
              </div>
            </div>
          </div>
          <video ref={trailerRef} src={movie.trailer} autoPlay loop className="trailer" />
          <p className="description">{movie.desc}</p>
          <div className="cast">
            <h3>Cast:</h3>
            <span className="castList">
              {movie.cast?.map((member, index) => (
                <span key={index}>{member.name}{index < movie.cast.length - 1 ? ', ' : ''}</span>
              )) || "Cast information not available"}
            </span>
          </div>

          {/* Display related movies if it's a series */}
          {isSeries && relatedMovies.length > 0 && (
            <div className="relatedMovies">
              <h3>Related Movies:</h3>
              <div className="relatedMoviesList">
                {relatedMovies.map((relatedMovie, index) => (
                  <div
                    key={index}
                    className="relatedMovieItem"
                    onClick={() => playRelatedMovie(index)} // Handle click on related movie
                  >
                    <img src={relatedMovie.imgSm} alt={relatedMovie.title} />
                    <p>{relatedMovie.title}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Video Modal */}
      {isVideoVisible && (
        <div className="video-modal">
          <button className="close-btn" onClick={closeVideo}>
            <Close />
          </button>

          {/* Previous Button */}
          {currentMovieIndex > 0 && (
            <button className="nav-btn prev-btn" onClick={playPreviousMovie}>
              <NavigateBefore style={{ fontSize: 50 }} />
            </button>
          )}

          <video controls autoPlay src={videoUrl} className="video-player" />

          {/* Next Button */}
          {currentMovieIndex < relatedMovies.length - 1 && (
            <button className="nav-btn next-btn" onClick={playNextMovie}>
              <NavigateNext style={{ fontSize: 50 }} />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default DetailedInfo;
