import React, { useState, useRef } from 'react';
import axios from "axios";
import "./register.scss";
import { useNavigate } from "react-router-dom";

export default function Register() {
    const navigate = useNavigate();
    
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");  // Now used
    const [username, setUsername] = useState("");  // Now used
    const [error, setError] = useState(null);      // Now used for error handling

    const emailRef = useRef();
    const passwordRef = useRef();
    const usernameRef = useRef();

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    });

    const handleStart = () => {
        setEmail(emailRef.current.value);
    };

    const handleFinish = async (e) => {
        e.preventDefault();
        
        setEmail(emailRef.current.value);
        setPassword(passwordRef.current.value);
        setUsername(usernameRef.current.value);
    
        try {
            await axiosInstance.post("auth/register", { email, username, password });
            navigate("/login");
        } catch (err) {
            setError("Registration failed. Please try again.");  // Now setting the error state
            console.error("Registration failed:", err.message);
        }
    };
    
    const handleLoginClick = () => {
        navigate('/login');
    };
    
    return (
        <div className='register'>
            <div className="top">
                <div className="wrapper">
                    <img
                        className="logo"
                        src="https://firebasestorage.googleapis.com/v0/b/joyful-a1987.appspot.com/o/breachfix%20log%20.svg?alt=media&token=fe244c7f-844f-4a3e-bbca-70e809a43515"
                        alt="Breachfix Logo" 
                    />
                    <button className="loginButton" onClick={handleLoginClick}>
                        Sign In
                    </button>
                </div>
            </div>
            <div className="container">
                <h1>Christian movies, TV shows, and more.</h1>
                <h2>Watch anywhere. Cancel at any time.</h2>
                <p>Ready to watch? Enter your email to create or restart your membership.</p>
                {!email ? (
                    <div className="input">
                        <input 
                            type="email" 
                            placeholder="Email address" 
                            ref={emailRef} 
                        />
                        <button className="registerButton" onClick={handleStart}>
                            Get Started
                        </button>
                    </div>
                ) : (
                    <form className="input" onSubmit={handleFinish}>
                        <input 
                            type="text" 
                            placeholder="Username" 
                            ref={usernameRef} 
                            onChange={(e) => setUsername(e.target.value)} // Track username changes
                        />
                        <input 
                            type="password" 
                            placeholder="Password" 
                            ref={passwordRef} 
                            onChange={(e) => setPassword(e.target.value)} // Track password changes
                        />
                        <button className="registerButton" type="submit">
                            Start
                        </button>
                    </form>
                )}
                {error && <p className="error">{error}</p>}  // Display the error if any
            </div>
        </div>
    );
}
