import "./FAQs.css";

import Footer from "./Footer/Footer";
import FAQ from "./Footer/FAQ";
import RegisterNavbar from "./RegisterNavBar/RegisterNavbar";
import Background from "./UI/Background/Background";

function FAQs() {
    return (
        <>
            <Background />
            <header>
                <RegisterNavbar />
            </header>
            <main>
                <FAQ />
               
            </main>
            <footer>
                <Footer />
            </footer>
        </>
    );
}

export default FAQs;