import React, { useState, useEffect, useRef } from 'react';
import "./home.scss";
import Navbar from '../../components/navbar/Navbar';
import Featured from '../../components/featured/Featured';
import List from '../../components/list/List';
import axios from 'axios'; 

const Home = ({ type }) => {
  const [lists, setLists] = useState([]);
  const [genre, setGenre] = useState(null);
  const cacheRef = useRef({}); // Cache to store fetched lists
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  });

  useEffect(() => {
    const getRandomLists = async () => {
      const cacheKey = `${type || "all"}_${genre || "all"}`;

      // Check if the lists for this type and genre are already cached
      if (cacheRef.current[cacheKey]) {
        setLists(cacheRef.current[cacheKey]);
        return;
      }

      try {
        const res = await axiosInstance.get(
          `lists${type ? "?type=" + type : ""}${genre ? "&genre=" + genre : ""}`, {
            headers: {
              token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
            },
          }
        );
        setLists(res.data);
        cacheRef.current[cacheKey] = res.data; // Cache the fetched data
      } catch (err) {
        console.log(err);
      }
    };

    getRandomLists();
  }, [type, genre, axiosInstance]); // Include genre in the dependencies

  return (
    <div className="home">
      <Navbar />
      <Featured type={type} setGenre={setGenre} />
      {lists.map((list, index) => (
        <List key={index} list={list} />
      ))}
    </div>
  );
}

export default Home;
