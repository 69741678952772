import React from 'react';
import "./welcome.css";
import FaqSection from "./components/FaqSection/FaqSection";
import Footer from "./components/Footer/Footer";
import GridItemsContainer from "./components/GridItemsContainer/GridItemsContainer";
import Hero from "./components/Hero/Hero";
import RegisterNavbar from "./components/RegisterNavBar/RegisterNavbar";
import Background from "./components/UI/Background/Background";
import { Link } from 'react-router-dom';

function Welcome() {
    return (
        <>
            <Background />
            <header>
                <RegisterNavbar />
            </header>
            <main>
                <Hero />
                <div className="home-watch-button-container">
                    <Link to="/home" className="home-watch-button">
                       {/* < Home/> */}
                    </Link>
                </div>
                <GridItemsContainer />
                <FaqSection />
            </main>
            <footer>
                <Footer />
            </footer>
        </>
    );
}

export default Welcome;
