import React, { useRef, useState, useEffect } from 'react';
import "./list.scss";
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@material-ui/icons';
import ListItem from '../listItem/ListItem';

export default function List({ list }) {
  const [isMoved, setIsMoved] = useState(false);
  const [slideNumber, setSlideNumber] = useState(0);
  const [maxSlide, setMaxSlide] = useState(0); // Maximum number of slides
  const listRef = useRef();

  useEffect(() => {
    const calculateMaxSlide = () => {
      const totalItems = list.content.length;
      const visibleItems = Math.floor(window.innerWidth / 230);
      setMaxSlide(totalItems - visibleItems);
    };

    calculateMaxSlide();
    window.addEventListener('resize', calculateMaxSlide);

    return () => {
      window.removeEventListener('resize', calculateMaxSlide);
    };
  }, [list.content.length]);

  const handleClick = (direction) => {
    setIsMoved(true);
    let distance = listRef.current.getBoundingClientRect().x - 50;

    if (direction === "left" && slideNumber > 0) {
      setSlideNumber(slideNumber - 1);
      listRef.current.style.transform = `translateX(${230 + distance}px)`;
    }
    if (direction === "right" && slideNumber < maxSlide) {
      setSlideNumber(slideNumber + 1);
      listRef.current.style.transform = `translateX(${-230 + distance}px)`;
    }
  };

  return (
    <div className="list">
      <span className="listTitle">{list.title}</span>
      <div className="wrapper">
        <ArrowBackIosOutlined
          className="sliderArrow left"
          onClick={() => handleClick("left")}
          style={{ display: !isMoved || slideNumber === 0 ? "none" : "block" }}
        />
        <div className="container" ref={listRef}>
          {list.content.map((item, i) => (
            <ListItem key={i} index={i} item={item} />
          ))}
        </div>
        <ArrowForwardIosOutlined
          className="sliderArrow right"
          onClick={() => handleClick("right")}
          style={{ display: slideNumber >= maxSlide ? "none" : "block" }}
        />
      </div>
    </div>
  );
}
